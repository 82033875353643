import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";

import SectionTitle from "../../components/section-title";
import { Icon } from "@iconify/react";
import React from "react";

const EnquireNow: React.FC<{ popup?: boolean }> = ({ popup }) => {
  return (
    <Box id="contact" py={popup ? 4 : 8} sx={{ bgcolor: "primary.main" }}>
      <Container>
        <SectionTitle align="center" title="Enquire Now" color="light" />

        <Stack
          display={"grid"}
          gridTemplateColumns={popup ? "repeat(1, 1fr)" : "repeat(1, 1fr)"}
          pt={4}
          columnGap={4}
          rowGap={popup ? 4 : 0}
        >
          {/* <EnquiryFrom /> */}
          <Stack alignItems={"center"}>
            <Typography
              color={"common.white"}
              sx={{ fontWeight: 600, display: "flex", alignItems: "center" }}
            >
              <Icon icon="material-symbols:call" />
              +91 88795 57452
            </Typography>
            <Typography color={"common.white"} variant="body1" my={2}>
              OR
            </Typography>
            <Typography
              color={"common.white"}
              sx={{ fontWeight: 600, display: "flex", alignItems: "center" }}
            >
              <Icon icon="ic:baseline-email" />
              haziquesk125@gmail.com
            </Typography>
            <Typography color={"common.white"} variant="body1" my={2}>
              OR
            </Typography>
            <Button
              href="https://wa.me/+918879557452"
              sx={{ bgcolor: "secondary.main" }}
              variant="contained"
              startIcon={<Icon icon="teenyicons:whatsapp-outline" />}
            >
              Contact On Whatsapp
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default EnquireNow;
