import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import MenuItems from "./menu-items";
import { Link } from "react-router-dom";
import theme from "../../theme";
import MenuOverlay from "../menu-overlay";

const Header: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const mdBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      sx={{ bgcolor: "transparent", position: "absolute", width: "100%" }}
      p={2}
      justifyContent={"space-between"}
    >
      <Container>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Box>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography
                variant="h1"
                color={"#fff"}
                fontSize={mdBreakPoint ? "32px" : "32px"}
              >
                <b>Best</b>CarRentals
              </Typography>
            </Link>
          </Box>
          {!mdBreakPoint ? (
            <Box>
              <MenuItems />
            </Box>
          ) : (
            <Box>
              <svg
                onClick={() => setMenuOpen(true)}
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="3rem"
                height="3rem"
                viewBox="0 0 40 40"
              >
                <path
                  fill="#fff"
                  d="M32.666 13.25H7.334a.5.5 0 0 1 0-1h25.332a.5.5 0 0 1 0 1m0 7.25H7.334a.5.5 0 0 1 0-1h25.332a.5.5 0 0 1 0 1m0 7.25H7.334a.5.5 0 0 1 0-1h25.332a.5.5 0 0 1 0 1"
                />
              </svg>
            </Box>
          )}
        </Stack>
        {isMenuOpen && <MenuOverlay setMenuOpen={setMenuOpen} />}
      </Container>
    </Stack>
  );
};

export default Header;
