const MENU_ITEMS = [
  {
    id: 1,
    label: "Home",
    link: "",
  },
  {
    id: 5,
    label: "About Us",
    link: '#about-us',
  },
  {
    id: 2,
    label: "Services",
    link: "#services",
  },
  {
    id: 3,
    label: "Fleets",
    link: "#fleets",
  },
  {
    id: 4,
    label: "Contact",
    link: "#contact",
  },
];

export default MENU_ITEMS;
