import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import EnquireNow from "../../sections/enquire-now";

const EnquireModal: React.FC<{
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
  carName: string
}> = ({ openModal, setOpenModal, carName }) => {
  return (
    <Modal
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      open={openModal}
    >
      <Box
        width={"80%"}
        sx={{ bgcolor: "primary.main", border: "1px solid #fff" }}
      >
        <Stack m={2} alignItems={"flex-end"}>
          <Button
            onClick={() => setOpenModal(false)}
            size="small"
            variant="contained"
            sx={{ width: "fit-content" }}
          >
            Close X
          </Button>
        </Stack>
        <Typography mx={4} color={'#fff'} variant="h4">{carName}</Typography>
        <EnquireNow popup />
      </Box>
    </Modal>
  );
};

export default EnquireModal;
