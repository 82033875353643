import React, { useEffect } from "react";

import routes from "./routes/routes";
import { useLocation, useRoutes } from "react-router-dom";
import Header from "./components/header";
import { Box } from "@mui/material";
import Footer from "./components/footer";

const App: React.FC = () => {
  const content = useRoutes(routes);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);


  return (
    // <div onScroll={() => console.log("scrolled")}>
    <Box>
      <Header />
      {content}
      <Footer />
    </Box>
    // </div>
  );
};

export default App;
