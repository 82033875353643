import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { useState } from "react";
import { ICar } from "../../config/type";
import EnquireModal from "./enquire-modal";

const CarCard: React.FC<{ car: ICar }> = ({ car }) => {
  const [openModal, setOpenModal] = useState(false);

  const [isHovered, setIsHovered] = useState(true);
  const CardHoverStyle = {
    transform: `scale(${isHovered ? 1.0125 : 1})`,
    transition: ".25s",
    textDecoration: "none",
  };
  return (
    <>
      <Card
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          setOpenModal(true);
        }}
        sx={[
          {
            boxShadow: "0 0 0 0 !important",
            border: "1px solid #16161650",
            bgcolor: "secondary.main",
            cursor: "pointer",
          },
          CardHoverStyle,
        ]}
      >
        <CardMedia
          sx={{ objectFit: "contain", height: "fit-content" }}
          component="img"
          height="fit-content"
          image={car?.image}
          alt={`${"vehicleType"} rental`}
        />
        <CardContent>
          <Typography
            color={"common.white"}
            gutterBottom
            variant="h6"
            component="div"
          >
            {car?.name.toUpperCase()}{" "}
            {car?.noOfSeats && `(${car?.noOfSeats} Seater)`}
          </Typography>
          <Typography color={"common.white"} variant="h6">
            Starting at Rs.{car?.pricePerKm}/km
          </Typography>
        </CardContent>
      </Card>
      <EnquireModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        carName={car?.name}
      />
    </>
  );
};

export default CarCard;
