import { v4 as uuidv4 } from 'uuid';

const USEFUL_LINKS = [
  {
    id: uuidv4(),
    label: "Car Rental Mumbai",
    link: "car-rental-mumbai",
  },
  {
    id: uuidv4(),
    label: "Car Rental Kurla",
    link: "car-rental-kurla",
  },
  {
    id: uuidv4(),
    label: "Car Rental Dadar",
    link: "car-rental-dadar",
  },
  {
    id: uuidv4(),
    label: "Car Rental Bandra",
    link: "car-rental-bandra",
  },
  {
    id: uuidv4(),
    label: "Car Rental Chembur",
    link: "car-rental-chembur",
  },
  {
    id: uuidv4(),
    label: "Car Rental Ghatkopar",
    link: "car-rental-ghatkopar",
  },
  {
    id: uuidv4(),
    label: "Car Rental Vikhroli",
    link: "car-rental-vikhroli",
  },
  {
    id: uuidv4(),
    label: "Car Rental Churchgate",
    link: "car-rental-chruchgate",
  },
  {
    id: uuidv4(),
    label: "Car Rental Marine Lines",
    link: "car-rental-marine-lines",
  },
  {
    id: uuidv4(),
    label: "Car Rental Grant Road",
    link: "car-rental-grant-road",
  },
  {
    id: uuidv4(),
    label: "Car Rental Lower Parel",
    link: "car-rental-lower-parel",
  },
  {
    id: uuidv4(),
    label: "Car Rental Matunga",
    link: "car-rental-matunga",
  },
  {
    id: uuidv4(),
    label: "Car Rental Santacruz",
    link: "car-rental-santacruz",
  },
  {
    id: uuidv4(),
    label: "Car Rental Thane",
    link: "car-rental-thane",
  },
  {
    id: uuidv4(),
    label: "Car Rental Vashi",
    link: "car-rental-Vashi",
  },
  {
    id: uuidv4(),
    label: "Car Rental Nerul",
    link: "car-rental-nerul",
  },
  {
    id: uuidv4(),
    label: "Car Rental Airoli",
    link: "car-rental-airoli",
  },
  {
    id: uuidv4(),
    label: "Car Rental Khargar",
    link: "car-rental-khargar",
  },
  {
    id: uuidv4(),
    label: "Car Rental Panvel",
    link: "car-rental-panvel",
  },
];

export default USEFUL_LINKS;
