import { Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import MenuItems from "../header/menu-items";
import theme from "../../theme";

const MenuOverlay: React.FC<{ setMenuOpen: (val: boolean) => void }> = ({
  setMenuOpen,
}) => {
  const mdBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        zIndex: 1,
        height: "100vh",
        width: "100%",
        bgcolor: "secondary.main",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        px: 4,
      }}
    >
      <Button
        size="small"
        variant="contained"
        sx={{ position: "absolute", top: 16, right: 16 }}
        onClick={() => setMenuOpen(false)}
      >
        Close
      </Button>
      <MenuItems setMenuOpen={setMenuOpen} isMobile={mdBreakPoint} />
    </Box>
  );
};

export default MenuOverlay;
