import { Stack, Typography } from "@mui/material";
import React from "react";
import MENU_ITEMS from "../../config/menu-items";

import { NavHashLink } from "react-router-hash-link";

const MenuItems: React.FC<{
  isMobile?: boolean;
  setMenuOpen?: (val: boolean) => void;
}> = ({ isMobile, setMenuOpen }) => {
  return (
    <Stack direction={isMobile ? "column" : "row"} columnGap={4}>
      {MENU_ITEMS.map((item) => (
        <Typography
          onClick={() => setMenuOpen && setMenuOpen(false)}
          variant="body1"
          py={isMobile ? 2 : 0}
          fontSize={isMobile ? 24 : "16px"}
        >
          <NavHashLink
            style={{ color: "#fff", textDecoration: "none" }}
            // to={item?.link}
            to={window.location.origin + "/" + item?.link}
          >
            {item?.label}
          </NavHashLink>
        </Typography>
      ))}
    </Stack>
  );
};

export default MenuItems;
