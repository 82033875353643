import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ bgcolor: "primary.main", height: "100vh" }}
    >
      <Typography color={{ color: "#fff" }} variant="h1">
        404
      </Typography>
      <Typography color={{ color: "#fff" }} variant="h2">
        Page Not Found {":("}
      </Typography>
      <Button onClick={()=> navigate('/')} sx={{ mt: 4 }} variant="outlined" color="secondary">
        Go Back To Homepage
      </Button>
    </Stack>
  );
};

export default PageNotFound;
