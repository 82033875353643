import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import CARS from "../../config/cars";
import { useNavigate, useParams } from "react-router-dom";
import BODY_TYPES from "../../config/cars-info";
import EnquireModal from "./enquire-modal";
import CarCard from "./car-card";
import theme from "../../theme";

const AllCars: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const bodyType = BODY_TYPES.find((car) => car.id === id);
  const filteredCars = CARS.filter((car) => car.vehicleTypeId === id);

  const mdBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box bgcolor={"primary.main"} pt={24} pb={16}>
      <Container>
        {filteredCars.length > 0 && (
          <Typography mb={4} color={"#fff"} variant="h5">
            All Cars In <b>{bodyType?.vehicleType.toUpperCase()}</b>
          </Typography>
        )}
        {filteredCars.length > 0 ? (
          <Stack
            gap={2}
            display={"grid"}
            gridTemplateColumns={mdBreakPoint ? "repeat(1, 1fr)" : "repeat(4, 1fr)"}
          >
            {filteredCars?.map((car) => (
              <CarCard car={car} />
            ))}
          </Stack>
        ) : (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            height={"100vh"}
          >
            <Typography color={"#fff"} variant="h4">
              Currently, there are no cars available. Please try again later.
            </Typography>
            <Button
              onClick={() => navigate("/")}
              sx={{ mt: 4 }}
              variant="outlined"
              color="secondary"
            >
              Go Back To Homepage
            </Button>
          </Stack>
        )}
      </Container>
    </Box>
  );
};

export default AllCars;
