import { v4 as uuidv4 } from "uuid";

const CARS = [
  {
    id: "1",
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/630x420/Hyundai/Verna/9744/1694602806760/front-left-side-47.jpg?tr=w-664",
    vehicleTypeId: "1",
    name: "Hyundai Verna",
    noOfSeats: [5],
    pricePerKm: 11,
  },
  {
    id: "2",
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/630x420/Maruti/Swift-Dzire/10243/1686044076724/front-left-side-47.jpg?tr=w-664",
    vehicleTypeId: "1",
    name: "Maruti Dzire",
    noOfSeats: [5],
    pricePerKm: 11,
  },
  {
    id: "3",
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/630x420/Maruti/Ertiga/10293/1697697779799/front-left-side-47.jpg?tr=w-664",
    vehicleTypeId: "3",
    name: "Maruti Ertiga",
    noOfSeats: [7],
    pricePerKm: 11,
  },
];

export default CARS;
