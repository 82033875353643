import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import FleetsSection from "../../sections/fleets-section";
import Hero from "../../sections/home/hero";
import AboutUs from "../../sections/about-us";
import ServiceCard from "../../components/service-card";
import HomeServicesSection from "../../sections/services/home-services-section";
import SectionTitle from "../../components/section-title";
import EnquireNow from "../../sections/enquire-now";
import Footer from "../../components/footer";

const HomePage: React.FC = () => {
  return (
    <Box px={0}>
      <Hero />

      <Divider />

      {/* About Us */}
      <AboutUs homeSection={true} />

      <Divider />
      {/* Services */}
      <HomeServicesSection />
      <Divider />

      {/* Fleets */}
      <Box id="fleets" sx={{bgcolor: 'primary.main'}}>
        <Container sx={{ py: 8 }}>
          <SectionTitle title="Body Types" color="light" />
          <Box pt={4}>
            <FleetsSection />
          </Box>
        </Container>
      </Box>

      <Divider />
      <EnquireNow />

      <Divider />
      
    </Box>
  );
};

export default HomePage;
