import { Stack, useMediaQuery } from "@mui/material";
import React from "react";
import FleetCard from "../fleet-card";
import BODY_TYPES from "../../config/cars-info";
import theme from "../../theme";

const Fleets: React.FC<{ data: any }> = ({ data }) => {
  const mdBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack display={"grid"} gridTemplateColumns={mdBreakPoint ? "repeat(1, 1fr)" : "repeat(4, 1fr)"} gap={2}>
      {data &&
        data?.map((car: any) => (
          <FleetCard
            id={car.id}
            image={car.image}
            vehicleType={car.vehicleType}
            pricePerKm={car.pricePerKm}
            noOfSeats={car.noOfSeats}
          />
        ))}
    </Stack>
  );
};

export default Fleets;
