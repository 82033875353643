import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import USEFUL_LINKS from "../../config/useful-links";
import theme from "../../theme";

const Footer: React.FC = () => {
  const mdBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ pt: 8, pb: 4, bgcolor: "secondary.main" }}>
      <Container>
        <Typography mb={2} color={"#fff"} variant="h5">
          Useful Links
        </Typography>

        <Stack
          rowGap={2}
          display={"grid"}
          gridTemplateColumns={
            mdBreakPoint ? "repeat(2, 1fr)" : "repeat(6, 1fr)"
          }
        >
          {USEFUL_LINKS.map((link) => (
            <Link key={link.id} style={{ color: "#fff" }} to={link.link}>
              <Typography variant="body2">{link.label}</Typography>
            </Link>
          ))}
        </Stack>
      </Container>
      <Divider sx={{ my: 4 }} />
      <Container>
        <Stack
          direction={mdBreakPoint ? "column" : "row"}
          justifyContent={"space-between"}
        >
          <Typography color={"#fff"}>
            Designed By{" "}
            <a
              style={{
                color: "#fff",
                fontWeight: 700,
                textTransform: "uppercase",
              }}
              href="https://shizan-vasim.netlify.app/"
              title="Sheezan Shaikh's Portfolio"
              target="_blank"
            >
              Sheezan Shaikh
            </a>
          </Typography>
          <Typography color={"#fff"}>
            © 2024 Best Car Rentals. All rights reserved
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
