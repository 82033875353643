import { RouteObject } from "react-router-dom";
import HomePage from "../pages/home";
import PageNotFound from "../pages/page-not-found/page-not-found";
import AllCars from "../pages/all-cars";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
    index: true,
  },
  {
    path: "/cars/:id",
    element: <AllCars />,
    index: true,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default routes;
