import { Divider, Typography } from "@mui/material";

const SectionTitle = ({
  title,
  color,
  align
}: {
  title: string;
  color: "light" | "dark";
  align?: "left" | "center" | "right";
}) => {
  return (
    <>
      <Typography align={align} variant="h2" color={color === "dark" ? "#000" : "#fff"}>
        {' - '}{title} {' - '}
      </Typography>
      {/* <Divider
        sx={{
          height: "4px",
          borderBottomWidth: "medium !important",
          bgcolor: color === "dark" ? "#000" : "#fff",
        }}
      /> */}
    </>
  );
};

export default SectionTitle;
