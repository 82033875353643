import {
  Button,
  Box,
  Stack,
  Typography,
  Container,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { HashLink } from "react-router-hash-link";
import theme from "../../../theme";

const Hero: React.FC = () => {
  const mdBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      px={4}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ bgcolor: "primary.main", height: "100vh" }}
    >
      <Container>
        <Stack
          direction={mdBreakPoint ? "column" : "row"}
          rowGap={mdBreakPoint ? 8 : 0}
        >
          <Stack width={mdBreakPoint ? "100%" : "50%"}>
            <Box>
              <Typography variant="h1" color={"common.white"}>
                The Best Car Rental Service in Mumbai
              </Typography>
              <Typography variant="h4" color={"common.white"} py={2}>
                Luxury Cars, Affordable Rates, and Hassle-Free Booking
              </Typography>
              <Button
                sx={{
                  "&:hover": { transform: "scale(1.0125)" },
                  color: "primary  ",
                }}
                color="secondary"
                variant="contained"
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <HashLink
                  style={{ textDecoration: "none", color: "#fff" }}
                  to="#fleets"
                >
                  Book Now or Get a Quote
                </HashLink>
              </Button>
            </Box>
          </Stack>
          {!mdBreakPoint && (
            <Stack width={"50%"}>
              <img
                src="innova-2.png"
                alt="Car Rental Service in Mumbai"
                width={"100%"}
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default Hero;
