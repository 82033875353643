import {
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SectionTitle from "../../components/section-title";
import theme from "../../theme";

interface Props {
  homeSection?: boolean;
}

const AboutUs: React.FC<Props> = ({ homeSection }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Box id="about-us" sx={{ bgcolor: "primary.main" }}>
      <Container sx={{ py: 8 }}>
        <SectionTitle color="light" title="About Us" />

        <Typography
          sx={{ color: theme.palette.common.white }}
          variant="h6"
          pt={4}
        >
          Welcome to Best Car Rentals Mumbai - Your Ultimate Choice for the Best
          Car Rental Service in Mumbai
        </Typography>

        <Typography
          sx={{ color: theme.palette.common.white }}
          variant="body1"
          py={2}
        >
          At <b>Best Car Rentals</b>, we pride ourselves on being the ultimate
          choice for car rentals in Mumbai, offering a diverse fleet of vehicles
          to cater to all your travel needs. Our commitment to providing the
          best car rental service is unwavering, and we strive to ensure your
          experience with us is nothing short of exceptional.
        </Typography>

        <Typography sx={{ color: theme.palette.common.white }} variant="body1">
          With a prime focus on customer satisfaction, we have carefully
          designed our services to cater to the varied needs of our clientele.
          Whether you're looking for a luxurious ride to attend a special event,
          an economical vehicle for everyday commuting, or a sturdy SUV for your
          family road trip, we have the perfect solution for you.
        </Typography>

        <Collapse in={isOpened}>
          <>
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="body1"
              py={2}
            >
              Our fleet boasts a wide range of vehicles, including sedans, SUVs,
              luxury cars, and more, all meticulously maintained to ensure
              optimal performance and safety. We understand that every journey
              is unique, which is why we offer flexible booking options,
              including hourly, daily, weekly, and monthly rentals, allowing you
              to tailor your rental period according to your specific
              requirements.
            </Typography>
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="body1"
            >
              At Best Car Rentals, we take pride in our transparent and
              hassle-free booking process. Our user-friendly platform enables
              you to book your desired vehicle with just a few clicks, and our
              knowledgeable customer support team is always on hand to assist
              you with any queries or concerns you may have.
            </Typography>
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="body1"
              py={2}
            >
              We understand the value of your time and strive to ensure that
              every aspect of your car rental experience is smooth and
              convenient. With Best Car Rentals, you can rest assured that
              you're getting the best car rental service in Mumbai, with
              affordable rates, top-notch vehicles, and unparalleled customer
              service.
            </Typography>
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="body1"
            >
              Experience the difference with Best Car Rentals and make your next
              journey a truly memorable one. Book with us today and discover why
              we are the preferred choice for car rentals in Mumbai.
            </Typography>
          </>
        </Collapse>

        <Button
          onClick={() => setIsOpened(!isOpened)}
          sx={{ mt: 4, color: "#fff" }}
          variant="contained"
          color="secondary"
        >
          Show {isOpened ? "Less" : "More"}
        </Button>
      </Container>
    </Box>
  );
};

export default AboutUs;
