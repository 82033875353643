import { v4 as uuidv4 } from "uuid";

const BODY_TYPES = [
  {
    id: `1`,
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/630x420/Maruti/Swift-Dzire-Tour/8863/1664262619298/front-left-side-47.jpg?tr=w-664",
    vehicleType: "sedan",
    noOfSeats: [4],
    pricePerKm: 11
  },
  {
    id: `2`,
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/630x420/Maruti/Ertiga/10293/1697697779799/front-left-side-47.jpg?tr=w-664",
    vehicleType: "suv",
    noOfSeats: [6],
    pricePerKm: 14
  },
  {
    id: `3`,
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/630x420/Toyota/Innova-Crysta/9612/1697698611076/front-left-side-47.jpg?tr=w-664",
    vehicleType: "suv",
    noOfSeats: [7],
    pricePerKm: 16
  },
  {
    id: `4`,
    image:
      "https://stimg.cardekho.com/images/carexteriorimages/630x420/BMW/5-Series/10576/1689668304044/front-left-side-47.jpg?tr=w-664",
    vehicleType: "luxury",
    noOfSeats: null,
    pricePerKm: 16
  }
];

export default BODY_TYPES;
