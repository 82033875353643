import { Container, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import ServiceCard from "../../components/service-card";
import SectionTitle from "../../components/section-title";
import theme from "../../theme";

const HomeServicesSection: React.FC = () => {
  const mdBreakPoint = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      id={"services"}
      sx={{ bgcolor: "primary.main", border: "1px solid #00000050" }}
      py={8}
    >
      <Container>
        <SectionTitle color={"light"} title="Services Offered" />
        <Stack
          pt={4}
          display={"grid"}
          gridTemplateColumns={mdBreakPoint ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
          columnGap={4}
          rowGap={mdBreakPoint ? 4 : 0}
        >
          <ServiceCard
            title="Vehicle Categories"
            imagePath="img/vehicle-categories.jpeg"
          />
          <ServiceCard
            title="Flexible Booking Options"
            imagePath="img/booking-on-call.jpg"
          />
          <ServiceCard title="Add-On Services" imagePath="img/driver.jpg" />
        </Stack>
      </Container>
    </Stack>
  );
};

export default HomeServicesSection;
