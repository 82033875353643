import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme";

interface Props {
  title: string;
  imagePath: string;
}

const ServiceCard: React.FC<Props> = ({ imagePath, title }) => {
  return (
    <Card sx={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          opacity: 0.5,
          backgroundColor: theme.palette.secondary.main,
          width: "100%",
          height: "100%",
        }}
      ></div>
      <CardMedia
        component={"img"}
        src={imagePath}
        alt={title}
        width={"240"}
        height={"240"}
      />
      <CardContent sx={{ position: "absolute", bottom: 0 }}>
        <Typography color={"#fff"} variant="h4">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
