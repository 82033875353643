import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import theme from "../../theme";

interface Props {
  id: string;
  image: string;
  vehicleType: string;
  pricePerKm: number;
  noOfSeats: Array<number> | null;
}

const FleetCard: React.FC<Props> = ({
  id,
  image,
  pricePerKm,
  vehicleType,
  noOfSeats,
}) => {
  const [isHovered, setIsHovered] = useState(true);
  const CardHoverStyle = {
    transform: `scale(${isHovered ? 1.0125 : 1})`,
    transition: ".25s",
    textDecoration: "none",
  };

  return (
    <Link
      to={`/cars/${id}`}
      style={CardHoverStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Card
        sx={{
          boxShadow: "0 0 0 0 !important",
          border: "1px solid #16161650",
          bgcolor: "secondary.main",
        }}
      >
        <CardMedia
          sx={{ objectFit: "contain", height: "100%" }}
          component="img"
          height="320"
          image={image}
          alt={`${vehicleType} rental`}
        />
        <CardContent>
          <Typography
            color={"common.white"}
            gutterBottom
            variant="h6"
            component="div"
          >
            {vehicleType.toUpperCase()} {noOfSeats && `(${noOfSeats} Seater)`}
          </Typography>
          <Typography color={"common.white"} variant="h6">
            Starting at Rs.{pricePerKm}/km
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default FleetCard;
